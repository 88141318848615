.stripe {
  max-height: 40px;
  opacity: 0.7;
  transition: all 0.7s;
  margin-right: 3px;
  margin-left: 5px;
}

.stripe:hover {
  opacity: 1;
}

.card-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ccc;
  padding: 18px 15px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
}

.creds-holder {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.button {
  margin-left: auto;
  margin-right: 5px;
  background: #5f6c36;
  border-radius: 3px;
  border: 0;
  padding: 11px 14px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  outline: 0;
}

.button:hover {
  opacity: 0.9;
}