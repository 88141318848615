.container {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  color: black;
}

.container input, .container textarea, .container button {
  margin: 15px;
  font-size: 16px;
  padding: 15px;
  width: min(700px, 100%);
  align-self: center;
}

.container textarea {
  height: 300px;
}

.success, .error {
  padding: 10px;
  width: min(700px, 100%);
}

.success {
  background-color: lightgreen;
}

.error {
  background-color: salmon;
}

.email-error {
  font-size: 11px;
  color: red;
}