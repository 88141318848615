.list {
  display: flex;
  flex-direction: column;
}

.list a {
  color: red;
}

.profile-content {
  width: 1024px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 33px;
}

.profile {
  background: #f6f7f7;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}