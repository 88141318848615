.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0;
  background: #5f6c36;
}

.header img {
  width: 300px;
  /*border: 1px solid #e8e8e8;*/
}

.user-block {
  padding: 15px;
  color: white;
  font-size: 16px;
  text-align: right;
}

.user-block a {
  color: black;
}