.customer-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    max-width: 120px;
  }

  .header {
    margin: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .name {
    font-size: 28px;
    margin: 10px;
    padding-top: 5px;
    // font-weight: bold;
  }
}

.video-container {
  max-width: 924px;
  width: 80%;
  text-align: center;

  .video-player {
    padding-top: 56.25%;
    height: auto;
    position: relative;

    video {
      width: 100%;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Themes
.dark {
  .customer-page {
    background-color: #242222;
  }

  .name {
    color: #999;
    border-top: 1px solid #666;
  }

  .video-player {
    background-color: #333;
  }
}

.light {
  .customer-page {
    background-color: #fff5e5;
  }

  .name {
    color: #555;
    border-top: 1px solid #aaa;
  }

  .video-player {
    background-color: rgba(0,0,0,0.06);
  }
}

// Media Queries
@media (max-width:480px)  { 
  .video-container {
    width: 100%;
  }
}
