.video-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.blurb {
  margin: 10px;
}

.checkout-holder {
  width: 400px;
}